import parse from "html-react-parser"
import React, { forwardRef, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import ImageContainer from "../../features/image-container"
import Slide from "../../utility/slide_content"
import Buttons from "../button"
import SVGIcon from "../SVGIcon"
import "./Block.scss"

const Block = (props, ref) => {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  return (
    <>
      <div className="Block" id={props.scrollId && `#${props.scrollId}`}>
        <Slide delay="200">
          <Container
            className={`Layout-container${props.containerClass ? "-big" : ""}`}
          >
            <Row className="lr-parent">
              <Col
                lg={props.lgLeft ? props.lgLeft : 5}
                className={`left ${props.className}`}
              >
                {props.html ? (
                  <>{parse(props.html)}</>
                ) : (
                  <>
                    {props?.tag && (
                      <div className="tag">
                        <p>{props?.tag}</p>
                      </div>
                    )}
                    {props?.heading && (
                      <div className="heading">
                        <h2>{props?.heading}</h2>
                      </div>
                    )}

                    {props?.content && (
                      <div className="content">
                        {parse(props?.content?.html)}
                      </div>
                    )}

                    {props?.description && (
                      <div className="description">
                        <p>{props?.description}</p>
                      </div>
                    )}

                    <div
                      className={`${
                        props?.contentBelowImage
                          ? screenWidth < 992
                            ? "dspnone"
                            : ""
                          : ""
                      }`}
                    >
                      {props?.points && (
                        <div className="points-wrap d-flex flex-wrap gap-16">
                          {props?.points?.map(pt => {
                            return (
                              <div className="point d-flex gap-8">
                                <SVGIcon
                                  name="pinkStar"
                                  className="pink-star"
                                />
                                <p className="m-0 fw-500">{pt}</p>
                              </div>
                            )
                          })}
                        </div>
                      )}

                      {(props.primaryButtonText ||
                        props.secondaryButtonText) && (
                        <div className="buttons">
                          {props.primaryButtonText && (
                            <Buttons
                              theme="primary"
                              arrow
                              link={props.primaryButtonLink}
                              text={props.primaryButtonText}
                            />
                          )}

                          {props.secondaryButtonText && (
                            <Buttons
                              theme={`secondary${
                                props.secondaryBtnTheme
                                  ? props.secondaryBtnTheme
                                  : ""
                              }`}
                              arrow
                              link={props.secondaryButtonLink}
                              text={props.secondaryButtonText}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Col>
              <Col
                lg={props.lgRight ? props.lgRight : 6}
                className={`right ${
                  props?.showBgColorImg ? "position-relative" : ""
                }`}
              >
                <ImageContainer
                  blockImageTitle={props?.imageTitle}
                  blockImage={props?.image}
                  blockIsIllustration={props?.isImage}
                  blockIsVideo={props?.isVideo}
                  decoration={props?.decoration}
                  ref={ref}
                />

                {props?.showBgColorImg ? (
                  <img
                    src={props?.showBgColorImg}
                    className="w-100 bgColorImg position-absolute"
                  />
                ) : (
                  <></>
                )}

                <div
                  className={`${
                    props?.contentBelowImage
                      ? screenWidth >= 992
                        ? "dspnone"
                        : ""
                      : "dspnone"
                  }`}
                >
                  {props?.points && (
                    <div className="points-wrap d-flex flex-wrap gap-16">
                      {props?.points?.map(pt => {
                        return (
                          <div className="point d-flex gap-8">
                            <SVGIcon name="pinkStar" className="pink-star" />
                            <p className="m-0 fw-500">{pt}</p>
                          </div>
                        )
                      })}
                    </div>
                  )}

                  {(props.primaryButtonText || props.secondaryButtonText) && (
                    <div className="buttons">
                      {props.primaryButtonText && (
                        <Buttons
                          theme="primary"
                          arrow
                          link={props.primaryButtonLink}
                          text={props.primaryButtonText}
                        />
                      )}

                      {props.secondaryButtonText && (
                        <Buttons
                          theme={`secondary${
                            props.secondaryBtnTheme
                              ? props.secondaryBtnTheme
                              : ""
                          }`}
                          arrow
                          link={props.secondaryButtonLink}
                          text={props.secondaryButtonText}
                        />
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Slide>
      </div>
    </>
  )
}

export default forwardRef(Block)
